import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import axios from 'axios'
import messagesRoutes from './messagesRoutes'

const axiosInstance = axios.create()
const messages = new messagesRoutes(axiosInstance)

let accessTokenFactory = undefined

function configure(apiServer) {
    axiosInstance.defaults.baseURL = (apiServer.ssl ? 'https' : 'http') + '://' + apiServer.host + ((apiServer.ssl && apiServer.port == '443') || (!apiServer.ssl && apiServer.port == '80') || !apiServer.port ? '' : ':' + apiServer.port)
}

function install(app) {
    app.config.globalProperties.$shoutApi = this
}

function useAuth(auth) {
    axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
        return config
    })
    accessTokenFactory = () => auth.accessToken
}

function buildStatusHub() {
    return new HubConnectionBuilder().withUrl(`${axiosInstance.defaults.baseURL}/status`, { accessTokenFactory }).withAutomaticReconnect().configureLogging(LogLevel.Information).build()
}

const apiService = {
    configure,
    install,
    useAuth,
    messages,
    buildStatusHub,
}

export default apiService
