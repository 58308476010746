import { createRouter, createWebHistory } from 'vue-router'

import Landing from '@/views/Landing'
import Resources from '@/views/Resources'
import Chat from '@/views/Chat'

const routes = [
    { path: '/', name: 'landing', component: Landing, meta: { requiresAuth: true } },
    { path: '/resources', name: 'resources', component: Resources, meta: { requiresAuth: true } },
    { path: '/chat', name: 'chat', component: Chat, meta: { requiresAuth: true } },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
