<template>
    <section>
        <pv-button label="Start a Chat" class="p-button-warning p-button-raised p-button-rounded tile text-5xl font-semibold" @click="$router.replace({ name: 'chat' })" />
        <pv-button label="Resource Library" class="p-button-info p-button-raised p-button-rounded tile text-5xl font-semibold" @click="$router.replace({ name: 'resources' })" />
    </section>
</template>

<script>
export default {
    async mounted() {},
    data() {
        return {}
    },
    methods: {},
}
</script>

<style scoped>
.tile {
    width: 20rem;
    height: 20rem;
    padding: 4rem;
    margin: 4rem;
}
</style>
