export default [
    {
        oldValue: 'call 999',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'ring 999',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'phone 999',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'contact 999',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'dial 999',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'text 999',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'reach out to 999',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: '999',
        newValue: 'your cell call bell'
    },
    {
        oldValue: 'call 111',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'ring 111',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'phone 111',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'contact 111',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'dial 111',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'text 111',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'reach out to 111',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: '111',
        newValue: 'your cell call bell',
    },
    {
        oldValue: 'call emergency services',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'ring emergency services',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'phone emergency services',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'contact emergency services',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'dial emergency services',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'text emergency services',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'reach out to emergency services',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'emergency services',
        newValue: 'your cell call bell',
    },
    {
        oldValue: 'call ems',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'ring ems',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'phone ems',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'contact ems',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'dial ems',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'text ems',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'reach out to ems',
        newValue: 'press your cell call bell',
    },
    {
        oldValue: 'send Start to 85258 to opt in again',
        newValue: 'send Start to opt in again',
    },
    {
        oldValue: 'Here.*helpful.*Take.*care.*shoutlink',
        newValue: 'You can find resources that many people find helpful in the shoutlink.info/waiting. We hope they are helpful to you too. Take care.',
        wholeMessage: true
    },
]
