<template>
    <pv-dialog v-model:visible="show" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" :modal="true" :draggable="false" :closable="false" class="bg-white border-round-xl">
        <template #header></template>
        This resource is not available in prisons. Please contact Healthcare for support.
        <template #footer>
            <pv-button label="Okay" icon="pi pi-check" @click="show = false" autofocus />
        </template>
    </pv-dialog>
</template>

<script>
export default {
    props: {
        modelValue: Boolean,
    },
    computed: {
        show: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            },
        },
    },
}
</script>

<style>
.p-dialog-header,
.p-dialog-footer,
.p-dialog-content {
    background-color: transparent !important;
}

.p-dialog-header {
    font-size: 1.5rem;
}

.p-dialog-content {
    font-size: 1.5rem;
}
</style>
