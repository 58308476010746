<template>
    <section class="layout">
        <div class="left">
            <ul id="resources">
                <li v-for="(resource, index) in resources" v-bind:key="index">
                    <div class="resource cursor-pointer" @click="showResource(resource)">
                        <span class="text-white text-xl font-semibold">{{ resource.name }}</span>
                        <span class="text-white text-base">{{ resource.subtitle }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="right">
            <span v-if="selectedResource?.pdf">
                <vue-pdf-embed ref="pdfRef" class="resourcePane" :source="selectedResource.pdf" disableAnnotationLayer disableTextLayer :page="page" @loaded="handleDocumentLoad" />
                <pv-button :disabled="page <= 1" icon="pi pi-chevron-left" class="PrevPageButton p-button-rounded p-button-danger" @click="page--" />
                <pv-button :disabled="page >= pageCount" icon="pi pi-chevron-right" class="NextPageButton p-button-rounded p-button-danger" @click="page++" />
            </span>
            <video v-else-if="selectedResource?.mp4" class="resourcePane" :src="selectedResource.mp4" autoplay muted loop />
            <div v-else id="instructions" class="text-white text-xl">Choose a resource from the list on the left.</div>
        </div>
    </section>
    <disclaimerModal v-model="showDisclaimerModal" />
</template>

<script>
import resources from '../resources'
import disclaimerModal from '../components/DisclaimerModal'
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    components: {
        disclaimerModal,
        VuePdfEmbed,
    },
    async mounted() {
        this.initialize()
    },
    data() {
        return {
            resources: [],
            selectedResource: undefined,
            showDisclaimerModal: false,
            page: null,
            pageCount: 1,
        }
    },
    watch: {
        selectedResource() {
            this.page = 1
        },
    },
    methods: {
        initialize() {
            this.resources = resources.filter((r) => !r.hidden)
        },
        showResource(resource) {
            if (this.selectedResource?.url === resource.url) return
            this.showDisclaimerModal = resource.showDisclaimer
            this.selectedResource = resource
        },
        handleDocumentLoad() {
            this.pageCount = this.$refs.pdfRef.pageCount
        },
    },
}
</script>

<style scoped>
.layout {
    width: 85%;
    height: 95%;
    background: #000000aa;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
}

.left {
    width: 25%;
    flex-direction: column;
    position: relative;
}

.resourcePane {
    width: 100%;
    height: 100%;
    border: 0;
    overflow-y: scroll;
}

.NextPageButton {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}
.PrevPageButton {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.right {
    width: 75%;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    overflow: hidden;
    position: relative;
}

#resources {
    padding: 2rem;
    margin: 0;
    list-style-type: none;
    overflow-y: scroll;
    height: 100%;
}

#resources .resource {
    display: flex;
    flex-direction: column;
    padding: 1vh;
}

#resources .resource:hover {
    background: #00000022;
}

#instructions {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
