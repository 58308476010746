<template>
    <div v-if="auth.isAuthenticated && auth.user?.profile?.role !== 'prisoner'">This app can only be used by accounts with a role claim of 'prisoner'.</div>
    <div v-else-if="auth.isAuthenticated && !auth.user?.profile?.site">This app can only be used by accounts with a valid site claim.</div>
    <div v-else-if="auth.isAuthenticated && !auth.user?.profile?.sentence">This app can only be used by accounts with a valid sentence claim.</div>
    <router-view v-else />
    <a class="logoutButton cursor-pointer" @click="auth.signOut()">
        <i class="pi pi-sign-out text-white text-2xl"></i>
    </a>
    <img src="./assets/shout.svg" class="logo cursor-pointer" @click="$router.replace({ name: 'landing' })" />
</template>

<script>
export default {
    inject: ['auth'],
}
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    user-select: none;
}

body {
    background-image: url('/src/assets/background.jpg');
    background-size: cover;
}

#app {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    font-family: Nunito;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.logo {
    position: absolute;
    width: 5rem;
    bottom: 1rem;
    right: 1rem;
}

.logoutButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
}
</style>
