export default class {
    constructor(axiosInstance) {
        this.axiosInstance = axiosInstance
    }

    all() {
        return this.axiosInstance.get('messages')
    }

    send(body) {
        return this.axiosInstance.post('messages', { body })
    }
}
