import { createApp } from 'vue'
import App from './App.vue'
import { createAuth } from '@/plugins/oidc'
import router from './router'
import shoutApi from './services/shoutApi'
import '@fontsource/nunito'
// import '@mdi/font/css/materialdesignicons.css'
import primeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import Button from 'primevue/button'
import Ripple from 'primevue/ripple'
import Dialog from 'primevue/dialog'

const app = createApp(App)

;(async () => {
    let response = await fetch(process.env.BASE_URL + 'config.json')
    const config = await response.json()
    const auth = createAuth(config.oidc)

    auth.useRouter(router)
    app.use(auth)

    app.use(router)

    shoutApi.configure(config.apiServer)
    shoutApi.useAuth(auth)
    app.use(shoutApi)

    app.use(primeVue, { ripple: true })

    app.component('pv-button', Button)
    app.component('pv-dialog', Dialog)
    app.directive('ripple', Ripple)

    app.mount('#app')
})()
