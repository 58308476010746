export default [
    {
        url: 'shoutlink.info/hurt_yourself_less',
        pdf: '01.pdf',
        name: 'Self-Harm Workbook',
        subtitle: 'For anyone who self-harms or feels at risk from self-harm.',
        showDisclaimer: true,
    },
    {
        url: 'shoutlink.info/AnxietyGuide',
        pdf: '02.pdf',
        name: 'Anxiety Self-Help Guide',
        subtitle: 'Tools to help you overcome anxiety.',
        showDisclaimer: true,
    },
    {
        url: 'shoutlink.info/Therapistaid_breathing',
        pdf: '03.pdf',
        name: 'Grounding Exercise for Anxiety',
        subtitle: 'Techniques to help control symptoms of anxiety.',
    },
    {
        url: 'shoutlink.info/panicattacks',
        pdf: '04.pdf',
        name: 'Managing Panic Attacks',
        subtitle: 'Seven steps to managing panic attacks.',
    },
    {
        url: 'shoutlink.info/privacy',
        pdf: '05.pdf',
        name: 'Shout 85258 User Privacy and Confidentiality Policy',
        subtitle: '',
        hidden: true,
    },
    {
        url: 'shoutlink.info/GIF',
        mp4: '06.mp4',
        name: 'Breathing Exercise',
        subtitle: 'Breathing exercise timer.',
    },
    {
        url: 'shoutlink.info/voicespdf',
        pdf: '07.pdf',
        name: 'Hearing Voices Coping Strategies',
        subtitle: 'Coping with the experiences of hearing voices.',
    },
    {
        url: 'shoutlink.info/groundingpdf',
        pdf: '08.pdf',
        name: 'Categories Game Grounding Exercise',
        subtitle: 'Focus the attention away from worrisome thoughts.',
    },
    {
        url: 'shoutlink.info/orientation_grounding',
        pdf: '09.pdf',
        name: 'Orientation Grounding Exercise',
        subtitle: 'Useful for people experiencing disorienting and intrusive thoughts.',
    },
]
