<template>
    <section class="layout">
        <div class="leftBase" :class="{ leftShowingResource: showResourcePane }">
            <span v-if="selectedResource?.pdf">
                <vue-pdf-embed ref="pdfRef" class="resourcePane" :source="selectedResource.pdf" disableAnnotationLayer disableTextLayer :page="page" @loaded="handleDocumentLoad" />
                <pv-button :disabled="page <= 1" icon="pi pi-chevron-left" class="PrevPageButton p-button-rounded p-button-danger" @click="page--" />
                <pv-button :disabled="page >= pageCount" icon="pi pi-chevron-right" class="NextPageButton p-button-rounded p-button-danger" @click="page++" />
            </span>
            <video v-else-if="selectedResource?.mp4" class="resourcePane" :src="selectedResource.mp4" autoplay muted loop />
            <pv-button icon="pi pi-times" class="closeResourceButton p-button-rounded p-button-danger" @click="hideResource" />
        </div>
        <div class="rightBase" :class="{ rightShowingResource: showResourcePane }">
            <div class="upper">
                <ul v-if="thread.length" id="bubbles">
                    <li v-for="(message, index) in thread" v-bind:key="index" :class="message.to ? 'you' : 'me'">
                        <div class="timestamp" v-if="index === 0 || (index > 0 && new Date(message.timestamp).toLocaleString() !== new Date(thread[index - 1].timestamp).toLocaleString())">
                            {{ index > 0 && new Date(thread[index - 1].timestamp).toLocaleDateString() === new Date(message.timestamp).toLocaleDateString() ? new Date(message.timestamp).toLocaleTimeString() : new Date(message.timestamp).toLocaleString() }}
                        </div>
                        <div class="message">
                            <template v-for="(elem, index) in processBody(message.body)" v-bind:key="index">
                                <span v-if="typeof elem === 'object' && (elem.pdf || elem.mp4)" class="cursor-pointer underline" @click="showResource(elem)">{{ elem.name }}</span>
                                <a v-else-if="typeof elem === 'object' && elem.url" :href="elem.url" target="_blank" rel="noopener noreferrer">{{ elem.name }}</a>
                                <span v-else-if="typeof elem === 'object' && elem.route" class="cursor-pointer underline" @click="$router.replace(elem.route)">{{ elem.name }}</span>
                                <span v-else-if="typeof elem === 'object'" class="cursor-pointer underline" @click="showResourceUnavailable()">{{ elem.name }}</span>
                                <template v-else>{{ elem }}</template>
                            </template>
                        </div>
                    </li>
                </ul>
                <div v-else id="instructions" class="text-white text-xl">Type your message in the box to start talking with a Shout Volunteer.</div>
            </div>
            <div class="lower">
                <div class="container">
                    <input ref="messageInput" placeholder="Type your message" class="text-white" v-model="bodyText" v-on:keyup.enter="send()" />
                    <a class="sendButton cursor-pointer" @click="send()">
                        <i class="pi pi-send text-blue-600 text-4xl"></i>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <disclaimerModal v-model="showDisclaimerModal" />
    <resourceUnavailableModal v-model="showResourceUnavailableModal" />
</template>

<script>
import resources from '../resources'
import substitutions from '../substitutions'
import disclaimerModal from '../components/DisclaimerModal'
import resourceUnavailableModal from '../components/ResourceUnavailableModal'
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    inject: ['auth'],
    components: {
        disclaimerModal,
        resourceUnavailableModal,
        VuePdfEmbed,
    },
    async mounted() {
        await this.backfillConversation()
        this.$nextTick(() => this.scrollToElement())
        this.startStatusHub()
        this.$refs.messageInput.focus()
    },
    data() {
        return {
            bodyText: '',
            statusHub: null,
            thread: [],
            selectedResource: undefined,
            showResourcePane: false,
            showDisclaimerModal: false,
            showResourceUnavailableModal: false,
            page: null,
            pageCount: 1,
        }
    },
    watch: {
        selectedResource() {
            this.page = 1
        },
    },
    methods: {
        logout() {
            this.auth.signOut()
        },
        async backfillConversation() {
            try {
                var response = await this.$shoutApi.messages.all()
                this.thread = response.data
            } catch (err) {
                console.log(err)
            }
        },
        startStatusHub() {
            try {
                this.statusHub = this.$shoutApi.buildStatusHub()

                this.statusHub.on('reply', (message) => {
                    this.receive(message)
                })

                this.statusHub.start()
            } catch (err) {
                console.log(err)
            }
        },
        async send() {
            try {
                if (!this.bodyText.length) return

                this.thread.push({ body: this.bodyText, timestamp: new Date().toJSON() })
                await this.$shoutApi.messages.send(this.bodyText)

                this.$nextTick(() => {
                    this.bodyText = ''
                    this.scrollToElement()
                    this.$refs.messageInput.focus()
                })
            } catch (err) {
                console.log(err)
            }
        },
        async receive(message) {
            try {
                this.thread.push(message)
                this.$nextTick(() => this.scrollToElement())
            } catch (err) {
                console.log(err)
            }
        },
        scrollToElement() {
            const el = document.getElementById('bubbles')
            if (el) {
                el.scrollTo({
                    top: el.scrollHeight,
                    behavior: 'smooth',
                })
            }
        },
        processBody(body) {
            substitutions
                .filter((s) => s.wholeMessage)
                .every((s) => {
                    const subRegex = new RegExp(s.oldValue, 'gi')
                    if (body.match(subRegex)) {
                        body = s.newValue
                        return false
                    } else return true
                })

            substitutions
                .filter((s) => !s.wholeMessage)
                .forEach((s) => {
                    const subRegex = new RegExp(s.oldValue, 'gi')
                    body = body.replace(subRegex, s.newValue)
                })

            const surveyLinkRegex = /https?:\/*s.alchemer.eu\/[^.]*/i
            const redirectionLinkRegex = /shoutlink.info\/waiting/i
            const resourceLinkRegex = /(shoutlink.info\/[^.]*)/i

            let elements = body.split(/(\s+)/)

            for (let i = 0; i < elements.length; i++) {
                if (elements[i] == ' ') continue

                var surveyUrlMatch = elements[i].match(surveyLinkRegex)
                var redirectionUrlMatch = elements[i].match(redirectionLinkRegex)
                var resourceUrlMatch = elements[i].match(resourceLinkRegex)

                if (surveyUrlMatch) {
                    elements[i] = { url: surveyUrlMatch[0], name: 'Click Here' }
                } else if (redirectionUrlMatch) {
                    elements[i] = { route: { name: 'resources' }, name: 'Resources Library' }
                } else if (resourceUrlMatch) {
                    var resourceMatch = resources.find((r) => r.url.localeCompare(resourceUrlMatch[1], undefined, { sensitivity: 'accent' }) === 0)
                    if (resourceMatch) {
                        elements[i] = resourceMatch
                    } else {
                        elements[i] = { name: resourceUrlMatch[1] }
                    }
                }
            }

            return elements
        },
        showResource(resource) {
            if (this.showResourcePane && this.selectedResource?.url === resource.url) return
            this.showDisclaimerModal = resource.showDisclaimer
            this.selectedResource = resource
            this.showResourcePane = true
            this.$refs.messageInput.focus()
        },
        handleDocumentLoad() {
            this.pageCount = this.$refs.pdfRef.pageCount
        },
        showResourceUnavailable() {
            this.showResourceUnavailableModal = true
        },
        hideResource() {
            this.showResourcePane = false
            this.$refs.messageInput.focus()
        },
    },
}
</script>

// CSS stolen from https://codepen.io/robinllopis/pen/mLrRRB via https://www.webtopic.com/html-css-bubbles-box-designs/
<style scoped>
.layout {
    width: 85%;
    height: 95%;
    background: #000000aa;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
}

.leftBase {
    width: 0%;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    overflow: hidden;
    position: relative;
}

.leftShowingResource {
    width: 60%;
}

.resourcePane {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.NextPageButton {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}
.PrevPageButton {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.rightBase {
    width: 100%;
    flex-direction: column;
    position: relative;
}

.rightShowingResource {
    width: 40%;
}

.upper {
    height: 100%;
    padding: 0 2rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
    display: none; /* for Chrome, Safari and Opera */
}

.lower {
    height: 6rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0 2rem;
}

.container {
    position: relative;
    background-color: #00000088;
    border-radius: 2rem;
    width: 100%;
    height: 4rem;
    margin: 0 auto;
}

input {
    position: absolute;
    background-color: transparent;
    width: calc(100% - 5rem);
    left: 2rem;
    top: 1rem;
    bottom: 1rem;
    border: none;
    font-size: 1.5rem;
    resize: none;
    outline: none;
}

textarea:focus {
    outline: none;
}

.closeResourceButton {
    position: absolute;
    left: 1rem;
    top: 1rem;
}

.sendButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

#bubbles {
    padding: 0 0 8rem 0;
    margin: 0;
    list-style-type: none;
    overflow-y: scroll;
    height: 100%;
}

#bubbles li {
    padding: 2rem 0 0 0;
}

#bubbles .timestamp {
    color: #ffffff;
    padding: 0 1.5rem 0.6rem;
    font-size: 1rem;
}

#bubbles .message {
    padding: 1rem 1.5rem;
    color: #fff;
    max-width: 75%;
    display: inline-block;
    text-align: left;
    border-radius: 2rem;
    font-size: 1.5rem;
}

#bubbles .me {
    text-align: right;
}

#bubbles .you {
    text-align: left;
}

#bubbles .you .message {
    background-color: #ff897c;
    color: #412970;
}

#bubbles .me .message {
    background-color: #89cbbb;
    color: #412970;
}

#instructions {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
